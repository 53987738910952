import React from 'react';
import { Col, Row, Image } from 'react-bootstrap'
import './scss/App.scss';
import cannon from './images/cannon.png';
import banner from './images/banner.png';
import ScrollAnimation from 'react-animate-on-scroll';

function App() {


  return (
    <div className="app">

      <div className="landing" >
        <div className="c">
          <div className="title">TNT Guild</div>
          <div className="sub">The revolution is here!</div>

          <div className="buttons">

            <a className="icon" target="_blank" rel="noreferrer" href="https://discord.gg/4zPkxAUDbm">
              <img className="invert" src="/images/discord-icon.png" alt="discord" />
            </a>

          </div>
        </div>


      </div>


      <div style={{ height: '5rem' }} />

      <h2 className="title">Why pick us?</h2>

      <br />

      <ScrollAnimation animateIn="fadeIn" duration={1500}>
        <Row style={{ maxWidth: '100%' }}>


          <Col sm={12} lg={6}>
            <Row>
              <Col />
              <Image src={cannon} width="500px"></Image>
              <Col sm lg={false} />
            </Row>
          </Col>

          <Col sm={12} lg={6} style={{ padding: '0px' }}>



            <div style={{ padding: '15px 0px', maxWidth: '500px' }}>
              <hr className="hr-text" data-content="Community" />
              <p className="hr-sub">We have various innovations in our arsenal, we make our own servers to help you improve in TNTWars and Warzone.</p>
            </div>

            <div style={{ padding: '15px 0px', maxWidth: '500px' }}>
              <hr className="hr-text" data-content="Innovative" />
              <p className="hr-sub">We have various innovations in our arsenal, we make our own servers to help you improve in TNTWars and Warzone.</p>
            </div>

            <div style={{ padding: '15px 0px', maxWidth: '500px' }}>
              <hr className="hr-text" data-content="Events" />
              <p className="hr-sub">We host exclusive events for our guild members.</p>
            </div>

            <div style={{ padding: '15px 0px', maxWidth: '500px' }}>
              <hr className="hr-text" data-content="Tournaments" />
              <p className="hr-sub">We host special tournaments for our guild members, and sometimes for the entire cubedcraft server.</p>
            </div>


          </Col>
        </Row>
      </ScrollAnimation>


      <div style={{ height: '10rem' }} />

      <div className="banner">
        <Image src={banner} fluid></Image>
        <div className="text">
          <h1>Join the revolution!</h1>

          <a className="icon" target="_blank" rel="noreferrer" href="https://discord.gg/4zPkxAUDbm" style={{ margin: 'auto' }}>
            <img className="invert" src="/images/discord-icon.png" alt="discord" />
          </a>
        </div>
      </div>

      <div style={{ backgroundColor: 'black', color: '#efefef', textAlign: 'center' }}>

        <div style={{ height: '2rem' }} />
        <p>Made by Jeroeno_Boy</p>
        <p>© 2021 TNT Guild</p>
        <div style={{ height: '2rem' }} />

      </div>

    </div>
  );
}

export default App;
